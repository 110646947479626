import React, { Fragment, useState, useEffect, useRef } from "react"
import Img from "gatsby-image"
import Cookies from "js-cookie"
import SEO from "../components/seo"
import Div100vh from "react-div-100vh"
import logo from "../images/AgeGate/logobud.svg"
import { Container, Row, Col } from "react-bootstrap"
import styles from "../scss/pages/_ageGate.module.scss"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { IsMobileDevice, calculateAgeNew, calculateDate } from "../utils/index"

const AgeGate = ({ path, ruta }) => {
  const [isMobile, setIsMobile] = useState(false)
  const [remember, setRemember] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const inputDay = useRef(null)
  const inputYear = useRef(null)
  const inputMonth = useRef(null)

  const [date, setDate] = useState({ dd: "", mm: "", aaaa: "" })
  const [calendar, setCalendar] = useState("AÑO")

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  const onChange = e => {
    let { name, value, validity } = e.target
    let newDate = { ...date }
    setDate({ ...date, [name]: value })
    if (name === "aaaa" && value.length >= 4 && validity.valid) {
      newDate.aaaa = value
      checkAge(newDate, "year")
    }
    if (name === "mm" && value.length >= 2 && validity.valid) {
      newDate.mm = value
      checkAge(newDate, "month")
    }
    if (name === "dd" && value.length >= 2 && validity.valid) {
      newDate.dd = value
      checkAge(newDate, "day")
    }
  }

  const checkAge = (date, who) => {
    let birthday = new Date()
    if (who === "year") {
      let year = birthday.getUTCFullYear()
      if (year - Number(date.aaaa) < 18) {
        window.dataLayer.push({
          event: "GAEvent",
          event_category: "Age Gate ",
          event_action: "Intercation",
          event_label: "No",
          interaction: false,
          component_name: "full_age_gate",
          element_text: "full_age_gate",
        })
        return (window.location.href = "https://www.poneloenpalabras.com.ar/")
      }
      if (year - Number(date.aaaa) >= 19) {
        sessionStorage.setItem("age", "true")
        setDisabled(false)
        window.dataLayer.push({
          event: "GAEvent",
          event_category: "Age Gate",
          event_action: "Intercation",
          event_label: "Yes",
          interaction: false,
          component_name: "",
          element_text: "",
        })
      } else {
        setCalendar("MES")
        setTimeout(() => {
          inputMonth.current.focus()
        }, 1)
        setDisabled(true)
      }
    }
    if (who === "month") {
      birthday.setDate(1)
      birthday.setMonth(Number(date.mm) - 1)
      birthday.setFullYear(Number(date.aaaa))
      let today = new Date()
      if (date.mm - 1 > today.getMonth()) {
        window.dataLayer.push({
          event: "GAEvent",
          event_category: "Age Gate ",
          event_action: "Intercation",
          event_label: "No",
          interaction: false,
          component_name: "full_age_gate",
          element_text: "full_age_gate",
        })
        return (window.location.href = "https://www.poneloenpalabras.com.ar/")
      }
      if (calculateAgeNew(birthday)) {
        sessionStorage.setItem("age", "true")
        setDisabled(false)
        window.dataLayer.push({
          event: "GAEvent",
          event_category: "Age Gate",
          event_action: "Intercation",
          event_label: "Yes",
          interaction: false,
          component_name: "",
          element_text: "",
        })
      } else {
        setCalendar("DIA")
        setTimeout(() => {
          inputDay.current.focus()
        }, 1)
        setDisabled(true)
      }
    }
    if (who === "day") {
      birthday.setDate(Number(date.dd))
      birthday.setMonth(Number(date.mm) - 1)
      birthday.setFullYear(Number(date.aaaa))
      if (calculateDate(birthday)) {
        sessionStorage.setItem("age", "true")
        setDisabled(false)
        window.dataLayer.push({
          event: "GAEvent",
          event_category: "Age Gate",
          event_action: "Interaction",
          event_label: "Yes",
          interaction: false,
          component_name: "",
          element_text: "",
        })
        window.dataLayer.push({
          event: "GAEvent",
          event_category: "Age Gate",
          event_action: "Interaction",
          event_label: "No",
          interaction: false,
          component_name: "full_age_gate",
          element_text: "full_age_gate",
        })
      } else {
        setCalendar("AÑO")
        setDate({ dd: "", mm: "", aaaa: "" })
        setDisabled(true)
        window.dataLayer.push({
          event: "GAEvent",
          event_category: "Age Gate ",
          event_action: "Interaction",
          event_label: "No",
          interaction: false,
          component_name: "",
          element_text: "",
        })
        window.location.href = "https://www.poneloenpalabras.com.ar/"
      }
    }
  }

  const handleRemember = () => {
    console.log(remember)
    if (remember) {
      setRemember(false)
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Age Gate",
        event_action: "Intercation",
        event_label: "Recuerdame",
        interaction: false,
        component_name: "",
        element_text: "",
      })
    } else {
      setRemember(true)
    }
  }

  const handleCookie = () => {
    if (remember) {
      Cookies.set("age", true, { expires: 7 })
    } else {
      sessionStorage.setItem("age", true)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    handleCookie()
    window.dataLayer.push({
      event: "GAEvent",
      event_category: "Age Gate",
      event_action: "Intercation",
      event_label: "Send",
      interaction: false,
      component_name: "",
      element_text: "",
    })

    if (ruta === "roadmap") {
      let getUrl = window.location
      window.location.href = getUrl.origin + "/roadmap"
    } else {
      return (window.location.href = ruta ? ruta : path)
    }
  }

  const data = useStaticQuery(graphql`
    query ageGate2 {
      file(base: { eq: "logoBud.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allFile(filter: { relativeDirectory: { eq: "AgeGate" } }) {
        edges {
          node {
            base
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Fragment>
      {isMobile ? (
        <Img
          fluid={data.allFile.edges[1].node.childImageSharp.fluid}
          loading="eager"
          className={styles.imgBackground}
        />
      ) : (
        <Img
          fluid={data.allFile.edges[2].node.childImageSharp.fluid}
          loading="eager"
          className={styles.imgBackground}
        />
      )}
      <div className={styles.gradient}>
        <Div100vh>
          <Container className={styles.AgeGatecontainer}>
            {/* <SEO title="AgeGate" /> */}

            {/* logo */}
            <Row className="d-flex justify-content-center align-self-start">
              <Col xs="auto" className="d-flex align-items-center">
                {/* <Img
                  fluid={data.file.childImageSharp.fluid}
                  className={styles.logo}
                /> */}
                <img src={logo} className={styles.logo} />
              </Col>
            </Row>

            {/* row center info */}
            <Row>
              <Col>
                {/* main paragraph */}
                <Row>
                  <Col className={styles.ContainerParagraph}>
                    <p className={styles.paragraph}>
                      ¿Tenés edad suficiente para
                      <br />
                      destapar una Bud?
                    </p>
                  </Col>
                </Row>

                <Row
                  className="d-flex justify-content-center"
                  style={{ paddingBottom: "2%" }}
                >
                  <form onSubmit={handleSubmit}>
                    {calendar === "AÑO" && (
                      <>
                        <div className={styles.dateName}>
                          <span className={styles.first}>Año </span>de
                          nacimiento
                        </div>

                        <input
                          maxLength={4}
                          name={"aaaa"}
                          onChange={onChange}
                          placeholder="AAAA"
                          value={date.aaaa}
                          ref={inputYear}
                          className={styles.input}
                          style={{ color: "#fff" }}
                          type="text"
                          pattern="[0-9]*"
                        />
                      </>
                    )}
                    {calendar === "MES" && (
                      <>
                        <div className={styles.dateName}>
                          <span className={styles.first}>Mes </span>de
                          nacimiento
                        </div>
                        <input
                          required
                          type="text"
                          pattern="[0-9]*"
                          maxLength={2}
                          name={"mm"}
                          onChange={onChange}
                          placeholder="MM"
                          value={date.mm}
                          ref={inputMonth}
                          className={styles.input}
                          style={{ color: "#fff" }}
                        />
                      </>
                    )}
                    {calendar === "DIA" && (
                      <>
                        <div className={styles.dateName}>
                          <span className={styles.first}>Día </span>de
                          nacimiento
                        </div>
                        <input
                          required
                          type="text"
                          pattern="[0-9]*"
                          maxLength={2}
                          name={"dd"}
                          onChange={onChange}
                          placeholder="DD"
                          value={date.dd}
                          ref={inputDay}
                          className={styles.input}
                          style={{ color: "#fff" }}
                        />
                      </>
                    )}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button
                        className={
                          disabled ? styles.button : styles.buttonActive
                        }
                        disabled={disabled}
                        type="submit"
                      >
                        DESTAPAR
                      </button>
                    </div>
                  </form>
                </Row>

                {/* checkbox remaind me */}
                <Row className="d-flex justify-content-center">
                  {/* <Col xs="auto" className="d-flex align-items-center"> */}
                  <label
                    className={styles.checkboxLabel}
                    onClick={handleRemember}
                  >
                    <input
                      type="checkbox"
                      aria-label="Recordarme"
                      className={styles.checkboxInput}
                    />
                    <span className={styles.checkboxText}>Recordarme</span>
                  </label>
                  {/* </Col> */}
                </Row>
              </Col>
            </Row>

            {/* footer paragraph */}
            <Row className={styles.containerFooter}>
              <Col xs="auto">
                <p className={styles.footerParagraph}>
                  BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS.
                  NO COMPARTA EL CONTENIDO CON MENORES.
                </p>
              </Col>
            </Row>
          </Container>
        </Div100vh>
      </div>
    </Fragment>
  )
}

export default AgeGate
